<script setup>
const { locale, locales } = useI18n()
const switchLocalePath = useSwitchLocalePath()

const { identifier } = useRoute().params

function getContentSlugSwitch(loc, route) {
  if (!route.meta.contentSlugs) return null

  const slugs = route.meta.contentSlugs.map((item) => ({
    slug: item.slug,
    lang: item.languages_code,
  }))

  return {
    from: slugs.find((item) => item.lang === locale.value),
    to: slugs.find((item) => item.lang === loc),
  }
}

function getProviderSlugSwitch(loc, route) {
  if (!route.meta.providerSlugs) return null

  const slugs = route.meta.providerSlugs.map((item) => ({
    slug: item.slug,
    lang: item.languages_code,
  }))

  return {
    from: slugs.find((item) => item.lang === locale.value),
    to: slugs.find((item) => item.lang === loc),
  }
}

const route = useRoute()

function evaluatePath(loc, route) {
  let path = switchLocalePath(loc.code)

  if (identifier) {
    path = path.replace(identifier, encodeURIComponent(identifier))
  }

  const contentSlugSwitch = getContentSlugSwitch(loc.code, route)

  if (contentSlugSwitch && contentSlugSwitch.from && contentSlugSwitch.to) {
    path = path.replace(
      new RegExp(`/${contentSlugSwitch.from.slug}$`),
      `/${contentSlugSwitch.to.slug}`
    )
  }

  const providerSlugSwitch = getProviderSlugSwitch(loc.code, route)

  if (providerSlugSwitch && providerSlugSwitch.from && providerSlugSwitch.to) {
    path = path.replace(
      new RegExp(`/${providerSlugSwitch.from.slug}/?`),
      `/${providerSlugSwitch.to.slug}/`
    )
  }

  return path
}
</script>

<template>
  <ul class="locale-list">
    <li v-for="loc in locales" :key="loc">
      <NuxtLink
        :key="loc.code"
        :replace="true"
        :class="{ activeLocale: loc.code === locale }"
        :to="evaluatePath(loc, route)"
        ><span>{{ loc.name }}</span>
      </NuxtLink>
    </li>
  </ul>
</template>

<style scoped lang="scss">
.locale-list {
  display: flex;
  gap: 0.4rem;
  li {
    a {
      display: inline-block;
      padding: 0.5rem;
      @include action-state-colors;
    }
  }
}

.activeLocale span {
  border-bottom: 1px solid var(--color-text);
}
</style>
